import gql from 'graphql-tag';

export const GET_ADVANCED_TOOLS_FAVOURITES = gql`
  query {
    userFavoriteAdvancedTools {
      advancedToolUuid
    }
  }
`;

export const ADD_ADVANCED_TOOLS_FAVOURITES = gql`
  mutation ($advancedToolUuid: ID!) {
    userAddFavoriteAdvancedTool(advancedToolUuid: $advancedToolUuid) {
      uuid
      userUuid
      advancedToolUuid
    }
  }
`;

export const REMOVE_ADVANCED_TOOLS_FAVOURITES = gql`
  mutation ($advancedToolUuid: ID!) {
    userRemoveFavoriteAdvancedTool(advancedToolUuid: $advancedToolUuid)
  }
`;
