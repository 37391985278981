import React, { forwardRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { navigate } from 'gatsby';
import Loading from '../../Common/Loading';

import { Column } from '../../Common/styled/Groups';
import { GreyLabel } from '../../Common/styled/GreyLabel';
import { ExtraBold, Span } from '../../Common/styled/Text';
import { Margin } from '../../Common/styled/Margins';
import {
  UI_TOOL_STATUS_ACTIVE,
  UI_TOOL_STATUS_CHECKING,
  UI_TOOL_STATUS_DEFAULT,
  UI_TOOL_STATUS_READY,
} from '../../../utils/advancedToolUtils';
import { CheckBoxUI } from '../../../componentsUI/CheckBox';
import { AdvancedToolUse } from '../use/AdvancedToolUse';
import { AlertUI } from '../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const StudyAdvancedToolUseDialog = ({ open, onClose, studyId, advancedTool, setAiDirectUse }) => {
  if (!advancedTool || !studyId) {
    return null;
  }

  const { t } = useTranslation();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [useTool, setUseTool] = useState(false);
  const [aiDirectUse, setDialogAiDirectUse] = useState();

  useEffect(() => {
    setDialogAiDirectUse(sessionStorage.getItem(advancedTool.uuid) === 'true');
  });

  /**
   * use AdvancedTool graphql migration in progress
   */
  const [usingTool, setUsingTool] = useState(false);

  /**
   * uiToolStatus defines the status of the pop-up window in case userInterface is type 1 or 2 (defined below)
   */
  const [uiToolStatus, setUiToolStatus] = useState(UI_TOOL_STATUS_DEFAULT);

  /**
   * Closes dialog modal if the Interface Window is not working in the automated log in process
   */
  const checkCloseModal = () => {
    if ([UI_TOOL_STATUS_READY, UI_TOOL_STATUS_ACTIVE].includes(uiToolStatus)) return;
    setError(false);
    onClose();
  };

  /**
   * Closes dialog modal and redirects to the Dicom Study page
   */
  const closeAndBackToStudy = () => {
    onClose();
    navigate(`/study/${studyId}`);
  };

  const provider = advancedTool && advancedTool.provider;
  const creditCost = advancedTool && advancedTool.creditCost;
  const creditText = creditCost === 1 ? 'credit' : 'credits';
  const checkboxLabel = { label: t('do.not.ask.for.advanced.tool.modal.check') };

  const handleCheckBox = (e) => {
    e.stopPropagation();
    if (setAiDirectUse) setAiDirectUse(!aiDirectUse);
    setDialogAiDirectUse(!aiDirectUse);
    sessionStorage.setItem(advancedTool.uuid, !aiDirectUse);
  };

  /**
   * Handler after "Accept" tool use has been clicked
   */
  const handleAdvancedToolUse = () => {
    setUseTool(true);
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={checkCloseModal}
    >
      {[
        UI_TOOL_STATUS_CHECKING,
        UI_TOOL_STATUS_READY,
        UI_TOOL_STATUS_ACTIVE,
      ].includes(uiToolStatus)
        ? <DialogTitle>{t('advanced.tool')}</DialogTitle>
        : <DialogTitle>{t('choose.tool.confirm')}</DialogTitle>}
      <DialogContent>
        <Margin mx-2 mt-2>
          <Column>
            <Span>
              <GreyLabel>{`${t('advanced.tool')}: `}</GreyLabel>
              <ExtraBold>{advancedTool && advancedTool.name}</ExtraBold>
            </Span>
            <Span>
              <GreyLabel>{`${t('provider')}: `}</GreyLabel>
              <ExtraBold>{provider.name}</ExtraBold>
            </Span>
          </Column>
        </Margin>
        <Margin mx-0 mt-2>
          {!!creditCost && (
            <Column className="text-center">
              <ExtraBold>{creditCost}</ExtraBold>
              <GreyLabel>{t(creditText)}</GreyLabel>
            </Column>
          )}
        </Margin>
        {[
          UI_TOOL_STATUS_READY,
          UI_TOOL_STATUS_ACTIVE,
        ].includes(uiToolStatus) && (
          <Margin mx-0 mt-4>
            <Column className="text-center">
              {uiToolStatus === UI_TOOL_STATUS_READY && <ExtraBold>{t('advanced.tool.ready.to.open')}</ExtraBold>}
              {uiToolStatus === UI_TOOL_STATUS_ACTIVE && <ExtraBold>{t('currently.advanced.tool.open')}</ExtraBold>}
            </Column>
          </Margin>
        )}
        {[
          UI_TOOL_STATUS_DEFAULT,
          UI_TOOL_STATUS_CHECKING,
        ].includes(uiToolStatus) && (
          creditCost > 0 ? (
            <Margin>
              <ExtraBold>{`${t('warning').toUpperCase()}: `}</ExtraBold>
              <Span>{t('advanced.tool.interface.warn')}</Span>
            </Margin>
          ) : (
            <Margin>
              <ExtraBold>{`${t('information').toUpperCase()}: `}</ExtraBold>
              <Span>{t('advanced.tool.interface.info')}</Span>
            </Margin>
          ))}
        {error && !usingTool && (
          <div className="d-flex justify-content-center">
            <AlertUI severity="error" title="Error">{error}</AlertUI>
          </div>
        )}
        <CheckBoxUI {...checkboxLabel} checked={aiDirectUse} props={{ handleChange: handleCheckBox }} />
        {usingTool && <Loading />}
      </DialogContent>
      <DialogActions>
        {[
          UI_TOOL_STATUS_READY,
          UI_TOOL_STATUS_ACTIVE,
        ].includes(uiToolStatus) && (
          <Button className={classes.button} disabled={uiToolStatus === UI_TOOL_STATUS_READY} color="primary" onClick={closeAndBackToStudy}>{t('return')}</Button>
        )}
        {[
          UI_TOOL_STATUS_DEFAULT,
          UI_TOOL_STATUS_CHECKING,
        ].includes(uiToolStatus) && (
          <>
            <Button className={classes.button} disabled={usingTool} color="primary" onClick={checkCloseModal}>{t('cancel')}</Button>
            <Button className={classes.button} disabled={usingTool} color="primary" onClick={handleAdvancedToolUse}>{t('accept')}</Button>
            <AdvancedToolUse
              onClose={onClose}
              studyId={studyId}
              advancedTool={advancedTool}
              setUsingTool={setUsingTool}
              setError={setError}
              uiToolStatus={uiToolStatus}
              setUiToolStatus={setUiToolStatus}
              useTool={useTool}
              setUseTool={setUseTool}
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
