import { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  ADD_ADVANCED_TOOLS_FAVOURITES,
  GET_ADVANCED_TOOLS_FAVOURITES,
  REMOVE_ADVANCED_TOOLS_FAVOURITES } from '../../../queries/AdvancedTools/Favorites';

export const AdvancedToolSetFavorite = ({ advancedTool, triggerToolEvent, setTriggerToolEvent, setUpdating }) => {
  if (!advancedTool) {
    return null;
  }

  const [addFavorite] = useMutation(
    ADD_ADVANCED_TOOLS_FAVOURITES,
    {
      refetchQueries: [{
        query: GET_ADVANCED_TOOLS_FAVOURITES,
      }],
      onCompleted: () => { setUpdating(false); },
      onError: () => { setUpdating(false); },
      awaitRefetchQueries: true,
    },
  );

  const [removeFavorite] = useMutation(
    REMOVE_ADVANCED_TOOLS_FAVOURITES,
    {
      refetchQueries: [{
        query: GET_ADVANCED_TOOLS_FAVOURITES,
      }],
      onCompleted: () => { setUpdating(false); },
      onError: () => { setUpdating(false); },
      awaitRefetchQueries: true,
    },
  );

  const toggleFavorite = (action) => {
    if (action === 'add') addFavorite({ variables: { advancedToolUuid: advancedTool.uuid } }).then();
    else removeFavorite({ variables: { advancedToolUuid: advancedTool.uuid } }).then();
    setUpdating(true);
  };

  useEffect(() => {
    if (triggerToolEvent) {
      toggleFavorite(triggerToolEvent);
      setTriggerToolEvent(null);
    }
  });

  return null;
};
