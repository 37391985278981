import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Container, Grid, Link, Paper, Typography } from '@material-ui/core';
import { Close, Memory, ThumbUp, ThumbUpOutlined } from '@material-ui/icons';

import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../utils/ObjectUtils';
import Loading from '../Common/Loading';
import { ButtonUI } from '../../componentsUI/Button';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import { StudyAdvancedToolUseDialog } from './modal/StudyAdvancedToolUseDialog';
import useStyles from '../AdvancedTools/styled/StudyAdvancedToolsMakeStyles';
import { InnerDivHTML } from '../Common/InnerHTML';
import { CheckBoxUI } from '../../componentsUI/CheckBox';
import { AdvancedToolUse } from './use/AdvancedToolUse';
import { Error } from '../Common/styled/Form';
import { UI_TOOL_STATUS_DEFAULT } from '../../utils/advancedToolUtils';
import { AdvancedToolSetFavorite } from './use/AdvancedToolSetFavorite';
import { ArticleContainerUI } from '../Common/styled/ArticleContainerUI';
import { getStudyApplicationPath } from '../Study/utils/StudyApplicationPath';
import { setHospitalEnvironment } from '../../utils/HospitalUtils';
import { Image } from '../Image/Image';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

const getProviderLinkText = (url) => url && url.replace(/(^\w+:|^)\/\//, '');
const getProviderLinkHref = (url) => url && `https://${getProviderLinkText(url)}`;

export const StudyAdvancedToolDetailView = ({ study, advancedTool, isFavorite }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const context = useContext(ThemeContext);
  const dispatch = useDispatch();
  const currentHospital = useSelector((state) => state.hospital);

  const hospital = study && (study.hospital ? study.hospital : study.medicalCase.patient.hospital);
  const availableTools = hospital.availableAdvancedTools.map((tool) => tool.uuid);

  if (currentHospital && currentHospital.uuid !== hospital.uuid) {
    setHospitalEnvironment({ uuid: hospital.uuid, dispatch, context });
  }

  /**
   * modal dialog for use tool
   */
  const [showUseToolModal, setShowUseToolModal] = useState(false);
  /**
   * state to trigger the AdvancedTool use in the AdvancedToolUse component
   */
  const [useTool, setUseTool] = useState(false);
  /**
   * use AdvancedTool graphql migration in progress
   */
  const [usingTool, setUsingTool] = useState(false);
  /**
   * state to trigger the AdvancedToolFavorite use in the AdvancedToolUse component
   */
  const [triggerToolFavoriteEvent, setTriggerToolFavoriteEvent] = useState(false);
  /**
   * use AdvancedToolFavorite graphql migration in progress
   */
  const [updatingFavorite, setUpdatingFavorite] = useState(false);
  /**
   * possible errors in the AdvancedTool graphql migration attempt
   */
  const [error, setError] = useState(false);
  /**
   * uiToolStatus defines the status of the pop-up window in case userInterface is type 1 or 2 (defined below)
   */
  const [uiToolStatus, setUiToolStatus] = useState(UI_TOOL_STATUS_DEFAULT);
  const toggleUseToolModal = () => setShowUseToolModal(!showUseToolModal);

  if (sessionStorage.getItem(advancedTool.uuid) === null) {
    sessionStorage.setItem(advancedTool.uuid, advancedTool && (advancedTool.creditCost === 0).toString());
  }

  const goBack = () => window.history.back();

  let certifications = [];
  if (advancedTool && advancedTool.ceCertification) certifications = [...certifications, 'CE'];
  if (advancedTool && advancedTool.fdaCertification) certifications = [...certifications, 'FDA'];

  const creditCost = advancedTool && advancedTool.creditCost;
  const creditText = creditCost === 1 ? 'credit' : 'credits';
  const [aiDirectUse, setAiDirectUse] = useState((sessionStorage.getItem(advancedTool.uuid) || false) === 'true');
  const checkboxLabel = { label: t('do.not.ask.for.advanced.tool.modal.check') };

  const handleCheckBox = (e) => {
    e.stopPropagation();
    setAiDirectUse(!aiDirectUse);
    sessionStorage.setItem(advancedTool.uuid, !aiDirectUse);
  };

  const handleAdvancedToolUse = () => {
    if (aiDirectUse === true) { setUseTool(true); return; }
    toggleUseToolModal();
  };

  const handleView = (evt) => {
    evt.stopPropagation();
    const images = [advancedTool.reportView];

    dispatch({ type: 'SET_VIEWER_IMAGES', viewerImages: { viewerImages: images.map((image) => ({ src: image })) } });
    dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: true } });
  };

  const toggleFavorite = () => {
    setTriggerToolFavoriteEvent(isFavorite ? 'remove' : 'add');
    setUpdatingFavorite(true);
  };

  const favoriteLabel = isFavorite ? 'remove.from.favorites' : 'add.to.favorites';
  const favoriteIcon = isFavorite ? ThumbUp : ThumbUpOutlined;
  const favoriteDisabled = updatingFavorite || usingTool;
  const availableTool = availableTools.indexOf(advancedTool.uuid) !== -1;

  const advancedToolButtons = availableTool ? [
    { name: favoriteLabel, icon: favoriteIcon, handleClick: toggleFavorite, disabled: favoriteDisabled  },
    { name: 'apply.tool', icon: Memory, handleClick: handleAdvancedToolUse, disabled: usingTool },
    { name: 'divider2', type: 'divider' },
  ] : [];

  const buttons = [
    ...advancedToolButtons,
    { name: 'go.back', icon: Close, handleClick: goBack },
  ];

  return (
    <ArticleContainerUI>
      <Navbar className={styles.subheader}>
        <SectionBar title="advanced.tool" items={buttons} breadcrumbs={getStudyApplicationPath(study)} />
        {study.uuid && advancedTool && (
          <StudyAdvancedToolUseDialog
            open={showUseToolModal}
            onClose={toggleUseToolModal}
            studyId={study.uuid}
            advancedTool={advancedTool}
            aiDirectUse={aiDirectUse}
            setAiDirectUse={setAiDirectUse}
          />
        )}
      </Navbar>
      <Container maxWidth="lg" className="article">
        <ScrollableContainer>
          {(isEmpty(advancedTool)) ? <Loading /> : (
            <Grid container spacing={2}>
              {availableTool && (
                <Grid item xs={12} md={11} lg={9}>
                  <Paper elevation={2} className={styles.wrapper}>
                    { advancedTool && (
                      <Box className={styles.box}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="h6" className={styles.toolName}>
                              {advancedTool.name && advancedTool.name.toUpperCase()}
                            </Typography>
                            <Typography variant="body1" className={styles.provider}>
                              {advancedTool.provider.name && advancedTool.provider.name.toUpperCase()}
                            </Typography>
                            <Box className={styles.bar} />
                            <Box className={styles.description}>
                              <InnerDivHTML content={advancedTool.description} />
                            </Box>
                            <Grid container spacing={1} className={styles.specifications}>
                              {certifications && !!certifications.length && (
                                <>
                                  <Grid item xs={5} className={styles.specsName}>regulatory</Grid>
                                  <Grid item xs={7} className={styles.specsValue}>{`${certifications.join(', ')} MARK`}</Grid>
                                </>
                              )}
                              <Grid item xs={5} className={styles.specsName}>
                                speciality
                              </Grid>
                              <Grid item xs={7} className={styles.specsValue}>
                                <Box>
                                  {advancedTool.speciality && t(advancedTool.speciality.toLowerCase()).toUpperCase()}
                                </Box>
                              </Grid>
                              <Grid item xs={5} className={styles.specsName}>
                                anatomy
                              </Grid>
                              <Grid item xs={7} className={styles.specsValue}>
                                <Box>
                                  {advancedTool.bodyRegion && t(advancedTool.bodyRegion.toLowerCase()).toUpperCase()}
                                </Box>
                              </Grid>
                              <Grid item xs={5} className={styles.specsName}>
                                image modality
                              </Grid>
                              <Grid item xs={7} className={styles.specsValue}>
                                <Box>
                                  {advancedTool.category && advancedTool.category.toUpperCase()}
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className={styles.description}>
                              <InnerDivHTML classes="ai-tool-section" content={advancedTool.info1} />
                            </Box>
                            {advancedTool.reportView && (
                              <Box className={styles.description}>
                                <Box className="ai-tool-section">
                                  <Typography variant="h1">
                                    {t('report.model').toUpperCase()}
                                  </Typography>
                                  <Box onClick={handleView}>
                                    <Image src={advancedTool.reportView} styles={styles.reportView} />
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box className={styles.image}>
                              {advancedTool.image && (
                                <img src={advancedTool.image} alt="AdvancedTool" />
                              )}
                            </Box>
                            <Box className={styles.imageFooter}>
                              <Link
                                variant="body2"
                                href={getProviderLinkHref(advancedTool.provider.web)}
                                target="_blank"
                                rel="noopener"
                                color="inherit"
                                underline="none"
                              >
                                {advancedTool.provider.web && getProviderLinkText(advancedTool.provider.web).toUpperCase()}
                              </Link>
                            </Box>
                            <Box className={styles.description}>
                              <InnerDivHTML classes="ai-tool-section" content={advancedTool.info2} />
                            </Box>
                            <Box className={styles.diseases}>
                              <InnerDivHTML content={advancedTool.info3} />
                            </Box>
                          </Grid>
                        </Grid>
                        <Box className={styles.cardFooter} />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Box className={styles.creditInfo}>
                                  <Typography component="span">
                                    {!!creditCost && `${t('credit.price').toUpperCase()}: `}
                                  </Typography>
                                  <Typography component="span">
                                    {creditCost === 0 ? t('available.at.no.cost') : `${creditCost} ${t(creditText)}`}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box className={styles.description}>
                          <CheckBoxUI {...checkboxLabel} checked={aiDirectUse} props={{ handleChange: handleCheckBox }} />
                        </Box>
                        <Box>
                          {usingTool && <Loading />}
                          {error && !usingTool && (
                            <div className="d-flex justify-content-center">
                              <Error>{error}</Error>
                            </div>
                          )}
                        </Box>
                        {advancedTool && (
                          <Box className={styles.group}>
                            <ButtonUI color="primary" disabled={usingTool} onClick={handleAdvancedToolUse}>{t('apply.tool')}</ButtonUI>
                            <AdvancedToolUse
                              studyId={study.uuid}
                              advancedTool={advancedTool}
                              setUsingTool={setUsingTool}
                              setError={setError}
                              uiToolStatus={uiToolStatus}
                              setUiToolStatus={setUiToolStatus}
                              useTool={useTool}
                              setUseTool={setUseTool}
                            />
                            <AdvancedToolSetFavorite
                              advancedTool={advancedTool}
                              triggerToolEvent={triggerToolFavoriteEvent}
                              setTriggerToolEvent={setTriggerToolFavoriteEvent}
                              setUpdating={setUpdatingFavorite}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </Paper>
                </Grid>
              )}
              {!availableTool && (
                <Grid item xs={12}>
                  <AlertWrapperUI>
                    <AlertUI severity="warning" title={t('info')}>
                      {t('this.advance.tool.is.not.available')}
                    </AlertUI>
                  </AlertWrapperUI>
                </Grid>
              )}
            </Grid>
          )}
        </ScrollableContainer>
      </Container>

    </ArticleContainerUI>
  );
};
