import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { StudyAdvancedToolDetailView } from '../../../components/AdvancedTools/StudyAdvancedToolDetailView';
import Layout from '../../../components/MenuLayout';
import { GET_ADVANCED_TOOL } from '../../../queries/AdvancedTools/AdvancedTools';
import { GET_STUDY } from '../../../graphql/queries';
import { GET_ADVANCED_TOOLS_FAVOURITES } from '../../../queries/AdvancedTools/Favorites';
import { isEmpty } from '../../../utils/ObjectUtils';
import Loading from '../../../components/Common/Loading';
import { AlertUI } from '../../../componentsUI/Alert';

export default ({ studyId, advancedToolId }) => {
  const { data: dataTool, loading, error } = useQuery(GET_ADVANCED_TOOL, { variables: { uuid: advancedToolId } });
  const { data: dataStudy, error: studyError } = useQuery(GET_STUDY, { variables: { uuid: studyId } });
  const { data: dataFav } = useQuery(GET_ADVANCED_TOOLS_FAVOURITES);

  const isFavorite = dataTool && dataTool.advancedTool && dataFav && dataFav.userFavoriteAdvancedTools
    && dataFav.userFavoriteAdvancedTools.some((tool) => tool.advancedToolUuid === dataTool.advancedTool.uuid);

  const study = dataStudy && dataStudy.dicomStudy;
  const advancedTool = dataTool && dataTool.advancedTool;

  return (
    <Layout>
      {error && error.graphQLErrors && error.graphQLErrors.length && (
        <AlertUI severity="error" title="Error">{error.graphQLErrors[0].message}</AlertUI>
      )}
      {studyError && studyError.graphQLErrors && studyError.graphQLErrors.length && (
        <AlertUI severity="error" title="Error">{studyError.graphQLErrors[0].message}</AlertUI>
      )}
      {
        loading || isEmpty(dataTool) || isEmpty(dataStudy)
          ? <Loading />
          : <StudyAdvancedToolDetailView study={study} advancedTool={advancedTool} isFavorite={isFavorite} />
      }
    </Layout>
  );
};
