import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GET_STUDY } from '../../../graphql/queries';
import { USE_ADVANCED_TOOL } from '../../../queries/AdvancedTools/AdvancedTools';
import {
  UI_TYPE_BACKEND,
  UI_TYPE_BRIDGE,
  UI_TYPE_WINDOW,
  UI_TYPE_AE_TITLE,
  UI_TOOL_STATUS_DEFAULT,
  UI_TOOL_STATUS_CHECKING,
  UI_TOOL_STATUS_READY,
  UI_TOOL_STATUS_ACTIVE,
  advancedToolCredentials } from '../../../utils/advancedToolUtils';

export const AdvancedToolUse = ({ onClose, studyId, advancedTool, setUsingTool, setError, useTool, setUseTool, setUiToolStatus }) => {
  if (!advancedTool || !studyId) {
    return null;
  }

  const { t } = useTranslation();
  const { data: study } = useQuery(GET_STUDY, { variables: { uuid: studyId } });
  const [target, setTarget] = useState(null);

  /**
   * toolBridgeNode is the pop-up window node that allows browser-communication between the Alma Health Platform cloud
   * and the advancedTool application.
   */
  const [toolBridgeNode, setToolBridgeNode] = useState(null);

  /**
   * @type {integer} userInterface defines the behavior of the advancedTool. It can be:
   *  - 0: the process will be done automatically sending the Dicom Study information from the backend
   *  - 1: a pop-up window opens and communicates to automatically logs in into the advancedTool application
   *  - 2: directly opens a pop-up window with the advancedTool application, log in is manually entered by the user
   *  - 3: the process will be done automatically sending the Dicom Study information from the backend with AE Title parameters
   */
  const userInterface = advancedTool.useIframe;

  const { username, password } = advancedToolCredentials.KoiosDs;

  /**
   * Closes the pop-up window toolBridgeNode (if it is open)
   */
  const closeBridgeWindow = () => {
    if ([UI_TYPE_BRIDGE, UI_TYPE_WINDOW].includes(userInterface) && toolBridgeNode) {
      toolBridgeNode.close();
    }
  };

  /**
   * Closes dialog modal and redirects to the Dicom Study page
   */
  const closeAndBackToStudy = () => {
    if (onClose) onClose();
    setTimeout(() => { navigate(`/study/${studyId}`); }, 5000);
  };

  /**
   * Use of the AdvancedTool on a Dicom Study where credits will be deducted from user's wallet or the use will be
   * denied if user has no remaining credits
   */
  const [useAdvancedTool] = useMutation(
    USE_ADVANCED_TOOL,
    {
      onCompleted: (data) => {
        toast(t('advanced.tool.use.confirmed'), { className: 'toast-success' });
        setUsingTool(false);
        setToolBridgeNode(toolBridgeNode);

        switch (userInterface) {
          case UI_TYPE_WINDOW:
            /**
             * If advancedTool is type 2 (directly opens advancedTool application window) the UI Tool status is set to
             * "active" so the user can close the modal dialog and go back to the Dicom Study page
             */
            setUiToolStatus(UI_TOOL_STATUS_ACTIVE);
            return;
          case UI_TYPE_BRIDGE:
            /**
             * If advancedTool is type 1 (bridge pop-up to manage automatic log in) the UI Tool status is set to "ready"
             * which makes the modal dialog to be locked until the pop-up returns a confirmation response message
             */
            setUiToolStatus(UI_TOOL_STATUS_READY);
            if (data.useAdvancedTool.uuid) {
              /**
               * Additionally, if a successful response is returned from the alma-cloud-backend, a message is sent to
               * the pop-up to complete the log in process
               */
              target.postMessage({ action: 'enable' }, advancedTool.endpoint);
              return;
            }
          // eslint-disable-next-line no-fallthrough
          case UI_TYPE_BACKEND:
          case UI_TYPE_AE_TITLE:
          default:
            /**
             * If advancedTool is type 0 (using backend message with no user interface window) a report is automatically
             * requested from the backend and the page redirects to the Dicom Study page
             */
            closeAndBackToStudy();
        }
      },
      onError: (errors) => {
        setUsingTool(false);
        if (errors.graphQLErrors && errors.graphQLErrors.length) {
          setUiToolStatus(UI_TOOL_STATUS_DEFAULT);
          setError(errors.graphQLErrors[0].message);
          toast(`${t('error').toUpperCase()}: ${errors.graphQLErrors[0].message}`, { className: 'toast-error' });
          if ([UI_TYPE_BRIDGE, UI_TYPE_WINDOW].includes(userInterface) && toolBridgeNode) toolBridgeNode.close();
        }
      },
      refetchQueries: [{
        query: GET_STUDY,
        variables: { uuid: studyId },
      }],
      awaitRefetchQueries: true,
    },
  );

  /**
   * Handler after "Accept" tool use has been clicked
   */
  const handleAdvancedToolUse = () => {
    /**
     * By default we notify ."
     */
    toast(t('advanced.tool.use.requested'), { className: 'toast-info' });
    /**
     * We set the state of the graphql migration process to true as "loading..."
     */
    setUsingTool(true);
    /**
     * If userInterface is 1 or 2 a pop-up window opens and the UI Tool status is set to "checking" which locks the
     * modal dialog and prevents it to be closed while the alma-cloud-backend returns tool use confirmation
     */
    if ([UI_TYPE_BRIDGE, UI_TYPE_WINDOW].includes(userInterface)) {
      setError(false);
      setUiToolStatus(UI_TOOL_STATUS_CHECKING);
      const dimensions = userInterface === UI_TYPE_BRIDGE ? 'width=200, height=200' : 'width=1100, height=700';
      const openWindow = window.open(
        `${advancedTool.endpoint}${study.dicomStudy.studyInstanceUid}`,
        'Alma_ToolBridge',
        `${dimensions}, menubar=no, location=no, resizable=no, left=10, top=10`,
      );
      openWindow.focus();
      setToolBridgeNode(openWindow);
    }
    /**
     * Either a pop-up has opened or not, the GraphQL query to the alma-cloud-backend is sent
     */
    useAdvancedTool({
      variables: {
        studyUuid: studyId,
        advancedToolUuid: advancedTool.uuid,
      },
    }).then();
  };

  const getUrlDomain = (url) => {
    const a = url.split('/');
    return `${a[0]}//${a[2]}`;
  };

  /**
   * Effect to handle the message listener from the pop-up window
   */
  useEffect(() => {
    const messageHandler = (event) => {
      if (!advancedTool) {
        return;
      }

      /**
       * If a message is received from a window out from the advancedTool endpoint domain the message is discarded
       */
      if (event.origin !== getUrlDomain(advancedTool.endpoint)) {
        return;
      }

      /**
       * In case the event received contains data and this data contains a message object...
       */
      if (event.data && event.data.message) {
        switch (event.data.message) {
          case 'bridge.window.loaded':
            /**
             * Confirmation of pop-up window loaded, in this case we set the variable "target" to asynchronously send a
             * message after advancedToolUse confirmation from the alma-cloud-backend
             */
            setTarget(event.source);
            break;
          case 'open.tool.window':
            /**
             * When the bridge pop-up confirms the AdvancedTool window is open and ready we receive this message and we
             * return in response the log in credentials
             */
            event.source.postMessage({ action: 'login', username, password }, advancedTool.endpoint);
            break;
          case 'close.bridge':
            /**
             * The message confirms that the login credentials have been submitted to the AdvancedTool window. Then we
             * close the bridge pop-up window and set the UI Tool status to "active" so the modal dialog can be closed
             * by the user
             */
            setUiToolStatus(UI_TOOL_STATUS_ACTIVE);
            closeBridgeWindow();
            break;
          default:
        }
      }
    };

    window.addEventListener('message', messageHandler);
    return () => window.removeEventListener('message', messageHandler);
  }, [toolBridgeNode]);

  useEffect(() => {
    if (useTool === true) {
      handleAdvancedToolUse();
      setUseTool(false);
    }
  });

  return null;
};
